export function formatSeconds(value) {
    value = Math.floor(value)

    if (value < 60 && value > 9) {
        return `00:${value}`
    }

    if (value < 60 && value < 10) {
        return `00:0${value}`
    }

    const minutes = Math.floor(value / 60)
    const seconds = value - minutes * 60

    if (minutes < 10 && seconds > 9) {
        return `0${minutes}:${seconds}`
    }

    if (minutes < 10 && seconds < 10) {
        return `0${minutes}:0${seconds}`
    }

    return `${minutes}:${seconds}`
}