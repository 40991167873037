import { useEffect, useState } from 'react'
import styleClasses from './TextLoader.module.css'

export const TextLoader = ({ isLoading }) => {
    const [title, setTitle] = useState('loading')

    useEffect(() => {
        setInterval(() => {
            setTitle(prev => prev.includes('...') ? 'loading' : prev + '.')
        }, 1000)
    }, [])

    if (!isLoading) {
        return null
    }

    return <div className={styleClasses.container}>
        <span className={styleClasses.loader}>{title}</span>
    </div>
}