import './App.css';
import Router from './components/Router/Router';
import { Toaster } from 'react-hot-toast';

function App() {
  window.Telegram.WebApp.headerColor = '#1c1f24'

  return (
    <div className="App">
      <Toaster />
      <Router/>
    </div>
  );
}

export default App;
