import { useState } from 'react'
import styleClasses from './Task.module.css'
import { MyModal } from '../MyModal/MyModal'
import { formatToKOrM } from '../../utils/number'
import { API } from '../../api/API'
import { Loader } from '../Loader/Loader'

export const Task = ({ task, onCompleteTask }) => {
    // const [isModalVisible, setIsModalVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)

    const classes = [styleClasses.container]
    if (task.statusId !== 0) {
        classes.push(styleClasses.completed)
    }

    function completeTask() {
        window.open(task.url, '_blank');
        API.completeTask(task.id).catch(console.error)
        onCompleteTask(task.id)
        closeTask()
    }

    function openTask(e) {
        if (task.statusId !== 0) {
            return
        }
        setIsModalVisible(true)
    }

    function closeTask(e) {
        setIsModalVisible(false)
    }

    return <div>
        <MyModal
            isVisible={isModalVisible}
            onClose={closeTask}
        >
            <div className={styleClasses.modalContainer}>
                <div className={styleClasses.taskTitle}>{task.name}</div>
                <div className={styleClasses.description}>{task.description}</div>
                <div className={styleClasses.btnContainer}>
                    <button className={styleClasses.btn} onClick={completeTask}>Выполнить</button>
                </div>
            </div>
        </MyModal>
        <div onClick={openTask} className={styleClasses.mainContainer}>
            <div className={styleClasses.loader}>
                <Loader isLoading={task.statusId === 1}/>
            </div>
            <div className={classes.join(' ')}>
                <img src={task.icon} className={styleClasses.icon} alt="" />
                <div className={styleClasses.content}>
                    <div className={styleClasses.name}>{task.name}</div>
                    <div className={styleClasses.income}>+{formatToKOrM(task.income)}</div>
                </div>
            </div>
        </div>
    </div>
}