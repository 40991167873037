class AudioAnalyser {
    constructor(audio, callback) {
        this.audioData = new Uint8Array(0);
        this.audioLevel = 0;
        this.audio = audio;
        this.values = [];
        this.timeout = 250;
        this.start(callback)
    }

    start(callback) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.source = this.audioContext.createMediaStreamSource(this.audio);
        this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);
        this.analyser = this.audioContext.createAnalyser();

        this.analyser.smoothingTimeConstant = 0.8;
        this.analyser.fftSize = 256;

        this.source.connect(this.analyser);
        this.analyser.connect(this.processor);
        this.processor.connect(this.audioContext.destination);

        this.processor.onaudioprocess = () => {
            let data = new Uint8Array(this.analyser.frequencyBinCount);
            this.analyser.getByteFrequencyData(data);
            let rms = 0;
    
            for (var i = 0; i < data.length; i++) {
                if (data[i]>120) data[i] = 120
                rms += data[i]*data[i]
            }
            rms = Math.sqrt(rms / data.length);
            this.values.push(rms)
        }

        this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
        this.analyser.getByteFrequencyData(this.dataArray);
        this.interval = setInterval(() => this.calcValue.bind(this)(callback), this.timeout)
    }

    calcValue(callback) {
        let sum = 0
        let length = this.values.length
        for (const value of this.values) {
            sum += value * value
        }
        const value = Math.floor(Math.sqrt(sum / length))
        this.values = []
        callback(value)
    }

    // tick() {
    //     this.analyser.getByteTimeDomainData(this.dataArray);
    //     this.setState({ audioData: this.dataArray });
    //     this.rafId = requestAnimationFrame(this.tick);
    //     let rms = 0;

    //     for (var i = 0; i < this.dataArray.length; i++) {
    //         if (this.state.audioData[i]>120) this.state.audioData[i] = 120
    //         rms += this.dataArray[i] * this.dataArray[i]
    //     }
    //     rms = Math.sqrt(rms / this.dataArray.length);
    //     this.values.push(rms)
    // }

    stop() {
        cancelAnimationFrame(this.rafId);
        this.analyser.disconnect();
        this.source.disconnect();
        this.processor.onaudioprocess = null
        clearInterval(this.interval)
    }

    // render() {
    //     return <textarea value={this.state.audioLevel} />;
    // }
}

export default AudioAnalyser;