import React, { useEffect, useState } from 'react';
import AudioAnalyser from '../AudioAnalyser/AudioAnalyser';
import { VertProgressBar } from '../VertProgressBar/VertProgressBar';
import styleClasses from './NoiseMeter.module.css'
import { formatSeconds } from '../../utils/time';

const thought = 50
const max = 100

const NoiseMeter = ({addCoin, leftSeconds, onStop, onStart, income, limitSeconds}) => {
  const [audio, setAudio] = useState(null);
  const [level, setLevel] = useState(0);
  const [analyzer, setAnalyzer] = useState(0);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);

  useEffect(() => {
    if (level > thought && leftSeconds > 0) {
      const power = Math.floor((level-thought) / (max - thought) * 100)
      return addCoin(power)
    }

    if (leftSeconds < 1) {
      stopMicrophone()
      return onStop()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level])

  function onStartMining() {
    if (leftSeconds > 0) {
      getMicrophone()
    }
  }

  async function getMicrophone() {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    }).catch(e => {
      console.error(e)
      if (e.toString().includes('Permission denied')) {
        setIsPermissionDenied(true)
        return
      }
      throw e
    });

    if (!audio) {
      return
    }

    setIsPermissionDenied(false)
  
    onStart()

    setAudio(audio)
  
    const analyzer = new AudioAnalyser(audio, (level) => {
      setLevel(level)
    });

    setAnalyzer(analyzer)
  }

  function stopMicrophone() {
    audio?.getTracks()?.forEach(track => track && track.stop && track?.stop());
    setAudio(null)
    if (analyzer) {
      analyzer?.stop();
    }
  }

  if (isPermissionDenied) {
    return <div className={styleClasses.container}>Пожалуйста, предоставьте<br/>доступ к микрофону 🎧🙏😎</div>
  }

  return (
    <div className={styleClasses.container}>
        {
          audio
            ? <VertProgressBar
              value={level}
              thought={thought}      
              max={max}
              onClick={()=>{
                stopMicrophone()
                onStop()
              }}        
            />
            : <div className={styleClasses.btn} onClick={onStartMining}>MINE</div>
        }
        <div className={styleClasses.leftCoins}>{formatSeconds(leftSeconds)} / {formatSeconds(limitSeconds)}</div>
        <div className={styleClasses.pulse}>{ !audio ? null : income ? `+${income} ℕ` : 'Шумите' }</div>
        </div>
  );
}

export default NoiseMeter;