import { useEffect, useRef, useState } from "react"
import NoiseMeter from "../../components/NoiseMeter/NoiseMeter"
import styleClasses from './MiningPage.module.css'
import { API } from "../../api/API"
import { formatToKOrM, numberWithComma } from "../../utils/number"
import { MyModal } from "../../components/MyModal/MyModal"
import { TextLoader } from "../../components/TextLoader/TextLoader"

export const MiningPage = () => {
    const [isLoading, setIsLoading] = useState(true)

    const [coins, setCoins] = useState(0)
    const [leftSeconds, setLeftSeconds] = useState(0)
    const [income, setIncome] = useState(0)
    const [autoIncome, setAutoIncome] = useState(0)
    const [newIncome, setNewIncome] = useState(0)
    const [limitSeconds, setLimitSeconds] = useState(0)
    const [parsingRatio, setParsingRatio] = useState(0)

    const [priceBoost, setPriceBoost] = useState(0)
    const [priceIncTime, setPriceIncTime] = useState(0)

    const [isMining, setIsMining] = useState(false)

    const intervalRef = useRef(null);
    const totalIncome = useRef(0);
    const startTime = useRef(null);
    const incomeCoins = useRef(0);

    useEffect(()=> {
        totalIncome.current = 0;
    }, [income])

    const autoIncomeInSecondRef = useRef(Math.floor(autoIncome / (60 * 60)));

    useEffect(() => {
        autoIncomeInSecondRef.current = Math.floor(autoIncome / (60 * 60));
    }, [autoIncome])

    useEffect(() => {
        setPriceIncTime(Math.floor(((limitSeconds - 290) ** 2) * 2))
    }, [limitSeconds])

    useEffect(() => {
        if (leftSeconds < 0) {
            setLeftSeconds(0)
        }
    }, [leftSeconds])

    useEffect(() => {
        setPriceBoost(Math.floor(parsingRatio ** 4 * 200))
    }, [parsingRatio])

    useEffect(()=> {
        API.getUserData().then((data) => {
            setCoins(data.balance)
            setLeftSeconds(data.leftSeconds)
            setAutoIncome(data.income)
            setLimitSeconds(data.limitSeconds)
            setParsingRatio(data.parsingRatio)

            setIsLoading(false)

            if (data.newIncome > data.income / 2) {
                setNewIncome(data.newIncome)
            }
        }).catch(console.error)

        setInterval(() => {
            if (!isMining) {
                setLeftSeconds(prevLeftSeconds => {
                    return prevLeftSeconds < limitSeconds ? prevLeftSeconds + limitSeconds / 300 : prevLeftSeconds
                });
            }
            API.ping().catch(console.error)
        }, 12000)

        setInterval(() => {
            setCoins((prevCoins)=> prevCoins + autoIncomeInSecondRef.current * 2)
        }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function addCoin(power) {
        const income = Math.floor(power / 10 * parsingRatio)
        totalIncome.current += income
        incomeCoins.current += income
        setCoins(coins + income)
        window?.Telegram?.WebView?.postEvent('web_app_trigger_haptic_feedback', () => {}, {type: "impact", impact_style: "medium"})
    }

    function onStart() {
        startTime.current = new Date();
        incomeCoins.current = 0;

        intervalRef.current = setInterval(() => {
            setLeftSeconds(prevLeftSeconds => prevLeftSeconds - 1);
            setIncome(totalIncome.current)
        }, 1000);
    }

    function onStop() {
        clearInterval(intervalRef.current)
        setIsMining(false)

        if (!startTime.current) {
            return
        }

        const stopTime = new Date()

        const duration = Math.floor((stopTime - startTime.current) / 1000)
        const coins = incomeCoins.current
        API.stopMining({
            duration,
            coins,
        }).catch(console.error)
    }

    function onBoost() {
        if (coins < priceBoost) {
            return
        }
        setParsingRatio((prev) => prev + 0.2)
        setCoins(prev => prev - priceBoost)
        API.boost().catch(console.error)
    }

    function onBoostTime() {
        if (coins < priceIncTime) {
            return
        }
        setCoins(prev => prev - priceIncTime)
        setLimitSeconds((prev) => prev + 5)
        API.boostTime().catch(console.error)
    }

    if (isLoading) {
        return <TextLoader isLoading={isLoading} />
    }

    return (<div className={styleClasses.container}>
        {
            <MyModal isVisible={newIncome} onClose={() => {setNewIncome(0)}}>
                <div className={styleClasses.modal}>
                    <div>Пока вас не было,<br/>вы заработали {formatToKOrM(newIncome)} N</div>
                    <div className={styleClasses.btn} onClick={() => setNewIncome(0)}>Получить</div>
                </div>
            </MyModal>
        }
        <div className={styleClasses.title}>NOISE COIN</div>
        <div className={styleClasses.balance}>
            <img className={styleClasses.coin} src='./icons/coin_ajfr86a28cbf.svg' alt='' />
            <div>{numberWithComma(coins)} ℕ</div>
        </div>
        <NoiseMeter
            addCoin={addCoin}
            onStart={onStart}
            onStop={onStop}
            leftSeconds={leftSeconds}
            limitSeconds={limitSeconds}
            income={income}
        />
        <div className={styleClasses.boostKeyboard}>
            <div
                className={priceIncTime > coins ? [styleClasses.boostBtn, styleClasses.boostDisabled].join(' ') : styleClasses.boostBtn}
                onClick={onBoostTime}
            >
                <div>Увеличить время</div>
                <div>+5s / {formatToKOrM(priceIncTime)}</div>
            </div>
            <div
                className={priceIncTime > coins ? [styleClasses.boostBtn, styleClasses.boostDisabled].join(' ') : styleClasses.boostBtn}
                onClick={onBoost}
            >
                <div>Буст майнинга</div>
                <div>x{(parsingRatio + 0.2).toFixed(1)} / {formatToKOrM(priceBoost)}</div>
            </div>
        </div>
    </div>)
}