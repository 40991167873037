import { useNavigate } from 'react-router-dom';
import styleClasses from './Menu.module.css'

export const Menu = () => {
    const navigate = useNavigate();

    return <div className={styleClasses.container}>
        <div onClick={()=>{navigate('/')}} className={styleClasses.item}>
            <img src='./icons/mine-cart_1876201.png' alt='' />
        </div>
        <div onClick={()=>{navigate('/miner')}} className={styleClasses.item}>
            <img src='./icons/smart_14957897.png' alt='' />
        </div>
        <div onClick={()=>{navigate('/tasks')}} className={styleClasses.item}>
            <img src='./icons/upgrade_8445886.png' alt='' />
        </div>
        <div onClick={()=>{navigate('/wallet')}} className={styleClasses.item}>
            <img src='./icons/icons8-wallet-50.png' alt='' />
        </div>
    </div>
}