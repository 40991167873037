export function numberWithComma(value) {
    return (+value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

export function numberWithCommaAndK(value) {
    const result = (+value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    if (value < 100000) {
        return result
    }
        if (value >= 100000 && value < 1000000) {
        let split = result.split(' ')
        split = split.slice(0, split.length - 1)
        return split.join(' ') + 'k'
    }

    return result;
}

export function formatToKOrM(num) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }

    return num.toString();
}
