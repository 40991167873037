import { useEffect, useState } from "react"
import styleClasses from './TaskListPage.module.css'
import { API } from "../../api/API"
import { Task } from "../../components/Task/Task"

export const TaskListPage = () => {
    const [isLoading, setIsLoading] = useState(true)

    const [tasks, setTasks] = useState([])

    useEffect(()=> {
        API.getTasks().then((tasks) => {
            tasks.sort((x,y) => (x === y)? 0 : x? -1 : 1)
            setTasks(tasks)
            setIsLoading(false)
        }).catch(console.error)
    }, [])

    const onCompleteTask = (id) => {
        const task = tasks.find(t => t.id === id)
        task.statusId = 1
        setTasks([...tasks])

        setTimeout(() => {
            API.getTasks().then((tasks) => {
                tasks.sort((x,y) => (x === y)? 0 : x? -1 : 1)
                setTasks(tasks)
            }).catch(console.error)
        }, 10000)
    }

    if (isLoading) {
        return <div className={styleClasses.title}>LOADING...</div>
    }

    return (<div className={styleClasses.container}>
        <div className={styleClasses.title}>NOISE COIN</div>
        <div className={styleClasses.tasks}>
            {
                tasks.map((task) => <Task task={task} key={task.id} onCompleteTask={onCompleteTask} />)
            }
        </div>
    </div>)
}