import axios from 'axios'
import { toast } from 'react-hot-toast';
import CryptoJS from 'crypto-js';

const serverURL = process.env.REACT_APP_NODE_ENV === 'local'
    ? 'http://127.0.0.1:3005/api/v1'
    : '/api/v1';

export class API {
    static async getUserData() {
        return this.doGetRequest('/users/me')
    }

    static async getAutoMinerTasks() {
        return this.doGetRequest('/auto-miner-tasks')
    }

    static async getTasks() {
        return this.doGetRequest('/tasks')
    }

    static async completeTask(id) {
        return this.doPostRequest(`/tasks/${id}`)
    }

    static async upgradeMining(task) {
        return this.doPatchRequest('/auto-miner-tasks/' + task.id, task)
    }

    static async stopMining(data) {
        return this.doPostRequest('/users/stop-mining', data)
    }

    static async ping() {
        return this.doPostRequest('/users/ping')
    }

    static async boost() {
        return this.doPostRequest('/users/boost')
    }

    static async boostTime() {
        return this.doPostRequest('/users/boost/time')
    }

    static async doGetRequest(endpoint) {
        try {
            const resp = await axios.get(serverURL + endpoint, { headers: this.getHeaders() })
            return resp?.data
        } catch (e) {
            if (e.response) {
                toast.error(`Ошибка: ${e.response.status} - ${e.response.data.message || 'Что-то пошло не так'}`);
            } else if (e.request) {
                toast.error('Ошибка сети: сервер не отвечает.');
            } else {
                toast.error('Ошибка: ' + e.message);
            }

            throw e
        }
    }

    static async doPostRequest(endpoint, body={}) {
        try {
            const resp = await axios.post(serverURL + endpoint, body, { headers: this.getHeaders(body) })

            return resp?.data
        } catch (e) {
            if (e.response) {
                toast.error(`Ошибка: ${e.response.status} - ${e.response.data.message || 'Что-то пошло не так'}`);
            } else if (e.request) {
                toast.error('Ошибка сети: сервер не отвечает.');
            } else {
                toast.error('Ошибка: ' + e.message);
            }

            throw e
        }
    }

    static getHeaders(body={}) {
        const timestamp = new Date().getTime().toString();
        const bodyWithTimestamp = { ...(body || {}), timestamp };

        const hash = CryptoJS.HmacSHA256(JSON.stringify(bodyWithTimestamp), this.getS()).toString();

        return {
            'X-Signature': hash,
            'X-Timestamp': timestamp,
            'Authorization': btoa(window.Telegram.WebApp.initData),
        };
    }

    static async doPatchRequest(endpoint, body=null) {
        try {
            const resp = await axios.patch(serverURL + endpoint, body, { headers: this.getHeaders(body) })
            return resp?.data
        } catch (e) {
            if (e.response) {
                toast.error(`Ошибка: ${e.response.status} - ${e.response.data.message || 'Что-то пошло не так'}`);
            } else if (e.request) {
                toast.error('Ошибка сети: сервер не отвечает.');
            } else {
                toast.error('Ошибка: ' + e.message);
            }

            throw e
        }
    }

    static getS() {
        const now = new Date()
        const h = now.getUTCHours()
        const d = now.getUTCDay()
        return `hef_${h / 2}_${d * 3}_tkfalfs`
    }
}