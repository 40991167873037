import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { MainLayout } from "../MainLayout/MainLayout";
import { AutoMinerPage } from "../../pages/AutoMinerPage/AutoMinerPage";
import { MiningPage } from "../../pages/MiningPage/MiningPage";
import { TaskListPage } from "../../pages/TaskListPage/TaskListPage";
import { WalletPage } from "../../pages/WalletPage/WalletPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainLayout><MiningPage /></MainLayout>
    ),
  },
  {
    path: "/miner",
    element: (
      <MainLayout><AutoMinerPage /></MainLayout>
    ),
  },
  {
    path: "/tasks",
    element: (
      <MainLayout><TaskListPage /></MainLayout>
    ),
  },
  {
    path: "/wallet",
    element: (
      <MainLayout><WalletPage /></MainLayout>
    ),
  },
]);

const Router = () => (<RouterProvider router={router} />)

export default Router