import styleClasses from './VertProgressBar.module.css'

export const VertProgressBar = ({ value, thought, max, onClick }) => {
    const isExceeds = value > thought
    const classes = isExceeds ? [styleClasses.line, styleClasses.exceeds] : [styleClasses.line]
    const percent = value > max ? 100 : (value / max) * 100
    return <div className={styleClasses.container} onClick={onClick} >
        <div className={styleClasses.limit} style={{bottom: `${thought * 2}px` }}></div>
        <div className={classes.join(' ')} style={{height: `${percent * 2}px` }}></div>
    </div>
}