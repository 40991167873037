import { formatToKOrM } from '../../utils/number'
import styleClasses from './AutoMinerButton.module.css'

export const AutoMinerButton = ({onUpgrade, available, task}) => {
    const { name, income, price, level, icon  } = task
    const classes = [styleClasses.container]
    if (!available) {
        classes.push(styleClasses.unavailable)
    }
    return <div className={classes.join(' ')} onClick={() => available && onUpgrade(task)} >
        <div className={styleClasses.content} >
            <div className={styleClasses.name} >
                {name}
            </div>
            <div className={styleClasses.level} >
                level {level}
            </div>
            <img className={styleClasses.icon} src={icon} alt='' />
            <div className={styleClasses.income} >
                +{formatToKOrM(income)} / {formatToKOrM(price)}
            </div>
        </div>
    </div>
}