import { useEffect, useRef, useState } from "react"
import styleClasses from './AutoMinerPage.module.css'
import { API } from "../../api/API"
import { AutoMinerButton } from "../../components/AutoMinerButton/AutoMinerButton"
import { numberWithComma, numberWithCommaAndK } from "../../utils/number"

export const AutoMinerPage = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isTasksLoading, setIsTasksLoading] = useState(true)

    const [coins, setCoins] = useState(0)
    const [income, setIncome] = useState(0)

    const [tasks, setTasks] = useState([])

    const incomeInSecondRef = useRef(Math.floor(income / (60 * 60)));

    useEffect(() => {
        incomeInSecondRef.current = Math.floor(income / (60 * 60));
    }, [income])


    useEffect(()=> {
        API.getUserData().then((data) => {
            setCoins(data.balance)
            setIncome(data.income)
            setIsLoading(false)
        }).catch(console.error)
        API.getAutoMinerTasks().then((tasks) => {
            setTasks(tasks)
            setIsTasksLoading(false)
        }).catch(console.error)

        setInterval(() => {
            setCoins((prevCoins)=> prevCoins + incomeInSecondRef.current)
        }, 1000)
    }, [])

    function onUpgrade(task) {
        setIncome(income+task.income)
        setCoins(coins-task.price)
        const oldTask = tasks.find(t => t.id === task.id)

        oldTask.level++;

        oldTask.price = Math.floor(Math.sqrt(oldTask.level + 2) * oldTask.price);
        oldTask.income = Math.floor(Math.sqrt(oldTask.level + 2) * oldTask.income / 1.2);

        setTasks([...tasks])
        API.upgradeMining(task)
    }

    if (isTasksLoading || isLoading) {
        return <div className={styleClasses.title}>LOADING...</div>
    }

    return (<div className={styleClasses.container}>
        <div className={styleClasses.title}>NOISE COIN</div>
            <div className={styleClasses.balance}>
                <img className={styleClasses.coin} src='./icons/coin_ajfr86a28cbf.svg' alt='' />
                <div>{numberWithComma(coins)}</div>
            </div>
            <div className={styleClasses.income}>
                <div>{numberWithCommaAndK(income)}</div>
                <div>/ час</div>
            </div>
            <div className={styleClasses.tasks}>
                {
                    tasks.map((task) => (<AutoMinerButton
                        task={task}
                        onUpgrade={onUpgrade}
                        available={task.price <= coins}
                        key={task.id}
                    />))
                }
            </div>
    </div>)
}